<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" md="3" lg="3" sm="12">
                <v-alert text color="warning" outlined height="120">
                    <v-row>
                        <v-col class="mt-3">
                            <h1 class="text-center">{{ numberOfDebtSale }}</h1>
                            <p class="text-center">وەصلی قەرز</p>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
            <v-col cols="12" md="3" lg="3" sm="12">
                <v-alert text color="success" outlined height="120">
                    <v-row>
                        <v-col class="mt-3">
                            <h1 class="text-center">{{ numberOfCashSale }}</h1>
                            <p class="text-center">وەصلی کاش</p>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-expansion-panels>
                        <v-expansion-panel class="elevation-0">
                            <v-expansion-panel-header>فلتەرکردن</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-form ref="report" @submit.prevent>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field @focus="$event.target.select()" v-model="from"
                                                            label="لە بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                            v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="from" @input="menu1 = false"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col>
                                                <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field @focus="$event.target.select()" v-model="to"
                                                            label="بۆ بەرواری" prepend-inner-icon="mdi-calendar" readonly
                                                            v-bind="attrs" v-on="on" outlined dense></v-text-field>
                                                    </template>
                                                    <v-date-picker v-model="to" @input="menu2 = false"></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col>
                                                <v-autocomplete v-model="customer_id" :items="customers"
                                                    item-value="customer_id" item-text="customer_name" dense outlined
                                                    label="کڕیار"></v-autocomplete>
                                            </v-col>
                                            <v-col>
                                                <v-btn @click="apply()" color="success"><v-icon>mdi-check</v-icon></v-btn>
                                                <v-btn @click="reset()" color="error"
                                                    class="mr-3"><v-icon>mdi-broom</v-icon></v-btn>
                                            </v-col>
                                            <v-col>

                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-data-table :items="profit" :headers="headers">
                        <template v-slot:[`item.total`]="{ item }">
                            {{ '$' + item.total }}
                        </template>
                        <template v-slot:[`item.profit`]="{ item }">
                            {{ '$' + item.profit }}
                        </template>
                        <template v-slot:[`item.discount`]="{ item }">
                            {{ '$' + item.discount }}
                        </template>
                        <template v-slot:[`body.append`]>
                            <tr>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center"></td>
                                <td class="text-center success white--text">{{ '$' + total.toLocaleString() }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            menu1: false,
            menu2: false,
            from: new Date().toISOString().substr(0, 10),
            to: new Date().toISOString().substr(0, 10),
            customer_id: 0,
            customers: [],
            profit: [],
            headers: [
                { text: 'ژمارەی وەصل', value: 'no', align: 'center' },
                { text: 'بەروار', value: 'date', align: 'center' },
                { text: 'کۆی گشتی', value: 'total', align: 'center' },
                { text: 'داشکاندن', value: 'discount', align: 'center' },
                { text: 'کڕیار', value: 'customer_name', align: 'center' },
                { text: 'قازانج', value: 'profit', align: 'center' },
            ]
        }
    },
    methods: {
        async getprofit(from = this.from, to = this.to, customer_id = 0) {
            const { data } = await this.axios.get('/report/profit', { params: { from, to, customer_id } })
            this.profit = data.profit
            this.numberOfCashSale = data.numberOfCashSale
            this.numberOfDebtSale = data.numberOfDebtSale
        },
        async getCustomers() {
            const { data } = await this.axios.get('/customer/forReference')
            this.customers = data
        },
        apply() {
            this.getprofit(this.from, this.to, this.customer_id)
        },
        reset() {
            this.from = new Date().toISOString().substr(0, 10),
                this.to = new Date().toISOString().substr(0, 10),
                this.customer_id = 0
            this.getprofit()
        }
    },
    created() {
        this.getprofit()
        this.getCustomers()
    },
    computed: {
        total() {
            let total = 0
            this.profit.forEach(prof => total += prof.profit)
            return total
        }
    }
}
</script>

<style></style>