var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3","sm":"12"}},[_c('v-alert',{attrs:{"text":"","color":"warning","outlined":"","height":"120"}},[_c('v-row',[_c('v-col',{staticClass:"mt-3"},[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.numberOfDebtSale))]),_c('p',{staticClass:"text-center"},[_vm._v("وەصلی قەرز")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"3","sm":"12"}},[_c('v-alert',{attrs:{"text":"","color":"success","outlined":"","height":"120"}},[_c('v-row',[_c('v-col',{staticClass:"mt-3"},[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.numberOfCashSale))]),_c('p',{staticClass:"text-center"},[_vm._v("وەصلی کاش")])])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-expansion-panels',[_c('v-expansion-panel',{staticClass:"elevation-0"},[_c('v-expansion-panel-header',[_vm._v("فلتەرکردن")]),_c('v-expansion-panel-content',[_c('v-form',{ref:"report",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',[_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"لە بەرواری","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"بۆ بەرواری","prepend-inner-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},on:{"focus":function($event){return $event.target.select()}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.customers,"item-value":"customer_id","item-text":"customer_name","dense":"","outlined":"","label":"کڕیار"},model:{value:(_vm.customer_id),callback:function ($$v) {_vm.customer_id=$$v},expression:"customer_id"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.apply()}}},[_c('v-icon',[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"mr-3",attrs:{"color":"error"},on:{"click":function($event){return _vm.reset()}}},[_c('v-icon',[_vm._v("mdi-broom")])],1)],1),_c('v-col')],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"items":_vm.profit,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s('$' + item.total)+" ")]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s('$' + item.profit)+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s('$' + item.discount)+" ")]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center"}),_c('td',{staticClass:"text-center success white--text"},[_vm._v(_vm._s('$' + _vm.total.toLocaleString()))])])]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }